import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtendedForecastData, WeatherData } from '../api/types';
import { fetchDayForecastData, fetchTimeForecastData, fetchWeatherData } from '../api/weather';

import { getNextSevenDays,getNextFiveHours } from '../Utils/dateUtils'
import { kelvinToCelcius } from '../Utils/unitConversion';
import { setIsInitial, setIsLoading } from '../Redux/reducers/appReducer';


export const fetchWeather = createAsyncThunk(
  'weather/fetchWeather',
  async (city: string | { lat: number; lng: number }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    dispatch(setIsLoading(true));

    try {
      const res = await Promise.all([fetchWeatherData(city), fetchDayForecastData(city), fetchTimeForecastData(city)]);
      dispatch(setIsLoading(false));

      if (res[0].cod === 200) {
        dispatch(setIsInitial(false));
        return res;
      }
      return rejectWithValue(res[0].message);
    } catch {
      dispatch(setIsLoading(false));
      return rejectWithValue('Error');
    }
  }
);



// export const transformWeatherData = (
//   res: any
// ): {
//   weather: WeatherData;
//   forecast: ExtendedForecastData[];
// } => {
//   // 기본 값으로 빈 배열을 사용
//   const weather = res[0] as WeatherData;
//   const forecast: ExtendedForecastData[] = [];

//   // 날씨 데이터 변환
//   weather.weather = res[0].weather[0];
//   weather.main = {
//     ...weather.main,
//     temp: kelvinToCelcius(weather.main.temp),
//     feels_like: kelvinToCelcius(weather.main.feels_like),
//     temp_max: kelvinToCelcius(weather.main.temp_max),
//     temp_min: kelvinToCelcius(weather.main.temp_min),
//   };
//   weather.wind.speed = Math.round(weather.wind.speed * 3.6); // m/s -> km/h 변환

//   // 7일간의 예측 데이터
//   const next7Days = getNextSevenDays();

//   // res[1]과 res[1].list가 유효한지 확인
//   if (res[1] && res[1].list && Array.isArray(res[1].list)) {
//     res[1].list.forEach((i: any, index: number) => {
//       forecast.push({
//         day: next7Days[index],
//         temp: {
//           temp_max: kelvinToCelcius(i.main.temp_max),
//           temp_min: kelvinToCelcius(i.main.temp_min),
//         },
//         weather: {
//           id: i.weather[0].id,
//           main: i.weather[0].main,
//         },
//       });
//     });
//   } else {
//     console.error('Forecast data is missing or invalid');
//   }

//   return {
//     weather,
//     forecast,
//   };
// };



export const transformWeatherData = (
  res: any
): {
  weather: WeatherData;
  dayforecast: ExtendedForecastData[];
  timeforecast: ExtendedForecastData[];
} => {
  const weather = res[0] as WeatherData;
  const dayforecast: ExtendedForecastData[] = [];
  const timeforecast: ExtendedForecastData[] = [];
  // 날씨 데이터 변환
  weather.weather = res[0].weather[0];
  weather.main = {
    ...weather.main,
    temp: kelvinToCelcius(weather.main.temp),
    feels_like: kelvinToCelcius(weather.main.feels_like),
    temp_max: kelvinToCelcius(weather.main.temp_max),
    temp_min: kelvinToCelcius(weather.main.temp_min),
  };
  weather.wind.speed = Math.round(weather.wind.speed * 3.6); // m/s -> km/h 변환

  const next7Days = getNextSevenDays(); // t 전달

  // 예측 데이터가 유효한지 확인
  if (res[1] && res[1].list && Array.isArray(res[1].list)) {
    res[1].list.forEach((i: any, index: number) => {
      dayforecast.push({
        day: next7Days[index],
        temp: {
          temp_max: kelvinToCelcius(i.main.temp_max),
          temp_min: kelvinToCelcius(i.main.temp_min),
        },
        weather: {
          id: i.weather[0].id,
          main: i.weather[0].main,
        },
      });
    });
  } else {
    console.error('Forecast data is missing or invalid');
  }

  const next5Hours = getNextFiveHours();
  if (res[2] && res[2].list && Array.isArray(res[2].list)) {
    res[2].list.forEach((i: any, index: number) => {
      timeforecast.push({
        day: next5Hours[index],
        temp: {
          temp_max: kelvinToCelcius(i.main.temp_max),
          temp_min: kelvinToCelcius(i.main.temp_min),
        },
        weather: {
          id: i.weather[0].id,
          main: i.weather[0].main,
        },
      });
    });
  } else {
    console.error('Forecast data is missing or invalid');
  }

  return {
    weather,
    dayforecast,
    timeforecast,
  };
};
