// import React from 'react';
// import { ReactComponent as GithubIcon } from '../../assets/github.svg';
// import { GithubLink, HeaderContainer, Title, HeaderIconsContainer } from './styed';
// import { useDispatch, useSelector } from 'react-redux';
// import DarkModeToggle from 'react-dark-mode-toggle';
// import { AppStore } from '../../Redux/store';
// import { toggleDarkMode } from '../../Redux/reducers/appReducer';

// const Header: React.FC = () => {
//   const dispatch = useDispatch();
//   const isDarkMode = useSelector((state: AppStore) => state.app.darkMode);

//   return (
//     <HeaderContainer>
//       <Title>우리 동네 날씨</Title>
//       <HeaderIconsContainer>
//         {/* <DarkModeToggle checked={isDarkMode} onChange={() => dispatch(toggleDarkMode())} size={60} />
//         */}
//       </HeaderIconsContainer>
//     </HeaderContainer>
//   );
// };

// export default Header;



import React from 'react';
import { ReactComponent as GithubIcon } from '../../assets/github.svg';
import { GithubLink, HeaderContainer, Title, HeaderIconsContainer } from './styed';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../Redux/store';
import { toggleDarkMode } from '../../Redux/reducers/appReducer';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation(); // useTranslation 훅 사용
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: AppStore) => state.app.darkMode);

  return (
    <HeaderContainer>
      <Title>{t('header.title')}</Title>
      <HeaderIconsContainer>
        {/* <DarkModeToggle checked={isDarkMode} onChange={() => dispatch(toggleDarkMode())} size={60} /> */}
      </HeaderIconsContainer>
    </HeaderContainer>
  );
};

export default Header;
