export function getNextSevenDays(): string[] {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const next7Days = [];
  for (let i = 0; i < 7; i++) {
    next7Days.push(days[new Date(Date.now() + (i + 1) * 24 * 60 * 60 * 1000).getDay()]);
  }
  return next7Days;
}

export function getNextFiveHours(): string[] {
  const nextFiveHours = [];
  const currentHour = new Date().getHours();

  for (let i = 1; i <= 5; i++) {
    const hour = (currentHour + i) % 24;
    nextFiveHours.push(hour < 10 ? `0${hour}:00` : `${hour}:00`);
  }
  
  return nextFiveHours;
}

// export function getNextSevenDays(t: (key: string) => string): string[] {
//   const days = [
//     t('days.sun'), 
//     t('days.mon'), 
//     t('days.tue'), 
//     t('days.wed'), 
//     t('days.thu'), 
//     t('days.fri'), 
//     t('days.sat')
//   ];

//   const next7Days = [];
//   for (let i = 0; i < 7; i++) {
//     next7Days.push(days[new Date(Date.now() + (i + 1) * 24 * 60 * 60 * 1000).getDay()]);
//   }
//   return next7Days;

// }