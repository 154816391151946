import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ImageSlider from './ImageSlider';
import UserProductPurchase from './UserProductPurshase';
import SimilarUserProducts from './SimilarUserProducts';
import {
  db,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
  collection,
  increment,
  arrayUnion,
  arrayRemove,
  writeBatch,
  limit,
  startAfter,
  orderBy,
} from './firebaseConfig';
import {
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  IconButton,
  Avatar,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import './css/detail.css';
import Product from './Product';
import moment from 'moment';
import { formatPrice } from './Utils/PriceFormatter';
import { getMediaType } from './Utils/FileHelper';
import useMessageBox from './Common/useMessageBox';
import useUserActivity from './useUserActivity';
import { likeProduct } from './Redux/reducers/floatingSidebarReducer';
import { fetchUserProfile } from './Utils/GetUserProfile';
import UserRatings from './UserRating';
import { fetchEventData } from './api/fetchEventData';
import Ribbon from './Weather/Ribbon';

function Detail() {
  const { t } = useTranslation();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();
  const [product, setProduct] = useState({});
  const [metaFiles, setMetaFiles] = useState([]);
  const [products, setProducts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMoreUserProducts, setHasMoreUserProducts] = useState(true);
  const [userProductsPage, setUserProductsPage] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [productOwnerName, setProductOwnerName] = useState('');
  const [productOwnerPhotoURL, setProductOwnerPhotoURL] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const { id: productId } = useParams();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const productOwnerId = product?.uid;

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const topRef = useRef(null);

  const { logViewedProduct } = useUserActivity(currentUser?.uid);

  const currentWeather = useSelector((state) => state.weather.weatherData);
  const [eventData, setEventData] = useState([]);

  // Firestore에서 이벤트 데이터를 가져오는 useEffect
  useEffect(() => {
    const fetchData = async () => {
      const events = await fetchEventData();
      setEventData(events);
    };
    fetchData();
  }, []);

  // 이벤트 조건을 확인하는 함수
  // const checkEventCondition = (event, currentWeather) => {
  //   if (!currentWeather || typeof currentWeather !== 'object') {
  //     console.error('Invalid currentWeather object:', currentWeather);
  //     return false;
  //   }

  //   switch (event.nameKey) {
  //     case 'rainyDayDiscount':
  //       return currentWeather.rain && currentWeather.rain['1h'] > 5; // 비가 시간당 10mm 이상일 때

  //     case 'sunnyDaySale':
  //       return (
  //         currentWeather.weather &&
  //         currentWeather.weather[0] &&
  //         currentWeather.weather[0].main === 'Clear'
  //       ); // 맑은 날씨일 때

  //     case 'snowyDaySpecial':
  //       // return currentWeather.wind && currentWeather.wind.speed > 1; // 바람이 특정 속도 이상일 때
  //       return currentWeather.snow && currentWeather.snow['1h'] > 5; // 눈이 시간당 5mm 이상일 때
  //     case 'heatwaveSavings':
  //       return currentWeather.main && currentWeather.main.temp > 35; // 기온이 35도 이상일 때

  //     default:
  //       return false;
  //   }
  // };
  const checkEventCondition = (event, currentWeather) => {
    // Ensure currentWeather is valid and is an object
    if (!currentWeather || typeof currentWeather !== 'object') {
      console.error('Invalid currentWeather object:', currentWeather);
      return false;
    }

    // Ensure event condition (thresholds) are present
    const eventCondition = event.condition;
    if (!eventCondition) {
      console.error('Event condition not found:', event);
      return false;
    }

    // Switch case for event conditions using dynamic thresholds from the event object
    switch (event.nameKey) {
      case 'rainyDayDiscount':
        // Use dynamic rain threshold from the event
        const rainThreshold = eventCondition ?? 5; // default to 5 if undefined
        return currentWeather.rain?.['1h'] > rainThreshold;

      case 'sunnyDaySale':
        // No thresholds needed, just check weather
        return currentWeather.weather?.[0]?.main === 'Clear';

      case 'snowyDaySpecial':
        // Use dynamic snow threshold from the event
        const snowThreshold = eventCondition ?? 5; // default to 5 if undefined
        return currentWeather.snow?.['1h'] > snowThreshold;

      case 'heatwaveSavings':
        // Use dynamic heatwave temperature threshold from the event
        const heatwaveTempThreshold = eventCondition ?? 35; // default to 35 if undefined
        return currentWeather.main?.temp > heatwaveTempThreshold;

      default:
        console.warn(`Unknown event condition: ${event.nameKey}`);
        return false;
    }
  };

  // Firestore에서 가져온 이벤트 데이터와 현재 날씨 정보를 기반으로 조건에 맞는 이벤트 찾기
  const activeEvent = eventData.find(
    (event) =>
      checkEventCondition(event, currentWeather) &&
      product.event?.id === event.id
  );
  // 할인가 계산
  const discountedPrice = activeEvent
    ? product.price * (1 - activeEvent.discount / 100)
    : product.price;

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'auto' });
    }
    if (selectedProduct && selectedProduct.id) {
      logViewedProduct(selectedProduct.id);
    }
  }, [selectedProduct]);

  const handleProductClick = (product) => {
    window.open(`/detail/${product.id}`, '_blank', 'noopener,noreferrer');
  };

  // useEffect(() => {
  //   const fetchDetails = async () => {
  //     if (!product || !product.uid) return;

  //     try {
  //       const productsCollection = collection(db, 'products');
  //       const q = query(
  //         productsCollection,
  //         where('uid', '==', product.uid),
  //         orderBy('createDate')
  //       );

  //       const querySnapshot = await getDocs(q);
  //       const productsData = querySnapshot.docs.map((_doc) => ({
  //         id: _doc.id,
  //         ..._doc.data(),
  //       }));

  //       // 중복 방지: 기존 제품과 새로운 제품 데이터 병합
  //       setProducts((prevProducts) => {
  //         const existingIds = new Set(prevProducts.map((p) => p.id));
  //         const uniqueProducts = productsData.filter(
  //           (p) => !existingIds.has(p.id)
  //         );
  //         return [...prevProducts, ...uniqueProducts];
  //       });

  //       const userProfile = await fetchUserProfile(product.uid);
  //       const newFiles = (product.xlarge_images || []).map((url, index) => ({
  //         index,
  //         fileUrl: url,
  //         type: getMediaType(url),
  //         senderName: userProfile?.displayName,
  //         date: product.createDate,
  //       }));
  //       setMetaFiles(newFiles);

  //       const isUserLiked =
  //         product?.likeUsers?.includes(currentUser?.uid) ?? false;
  //       setIsLiked(isUserLiked);
  //       dispatch(likeProduct(productId));
  //     } catch (error) {
  //       console.error('Error fetching details: ', error);
  //     }
  //   };

  //   fetchDetails();
  // }, [product, currentUser, dispatch, productId]);

  useEffect(() => {
    let isMounted = true;

    const fetchProduct = async () => {
      try {
        const productRef = doc(db, 'products', productId);
        const productSnapshot = await getDoc(productRef);
        const productData = productSnapshot.data();

        if (productData && isMounted) {
          const formattedData = {
            id: productSnapshot.id,
            ...productData,
            formattedDate: moment(productData.createDate?.toDate()).fromNow(),
          };
          setProduct(formattedData);
          setSelectedProduct(formattedData);

          const newFiles = (formattedData.xlarge_images || []).map(
            (url, index) => ({
              index,
              fileUrl: url,
              type: getMediaType(url),
              senderName: '',
              date: formattedData.createDate,
            })
          );
          setMetaFiles(newFiles);
        }
      } catch (error) {
        console.error('Error fetching product: ', error);
      }
    };

    if (productId) {
      fetchProduct();
    }
    return () => {
      isMounted = false;
    };
  }, [productId]);

  // const fetchUserProducts = useCallback(async () => {
  //   if (!product?.uid) return;

  //   const productsQuery = lastVisible
  //     ? query(
  //         collection(db, 'products'),
  //         where('uid', '==', product.uid),
  //         orderBy('createDate'),
  //         startAfter(lastVisible),
  //         limit(10)
  //       )
  //     : query(
  //         collection(db, 'products'),
  //         where('uid', '==', product.uid),
  //         orderBy('createDate'),
  //         limit(10)
  //       );

  //   const productsSnapshot = await getDocs(productsQuery);
  //   const fetchedProducts = productsSnapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));

  //   if (fetchedProducts.length === 0) {
  //     setHasMoreUserProducts(false);
  //   } else {
  //     setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
  //     setLastVisible(productsSnapshot.docs[productsSnapshot.docs.length - 1]);
  //   }
  // }, [product?.uid, lastVisible]);

  // const fetchUserProducts = useCallback(async () => {
  //   if (!product?.uid || !lastVisible) return;

  //   const productsQuery = query(
  //     collection(db, 'products'),
  //     where('uid', '==', product.uid),
  //     orderBy('createDate'),
  //     startAfter(lastVisible),
  //     limit(10)
  //   );

  //   const productsSnapshot = await getDocs(productsQuery);
  //   const fetchedProducts = productsSnapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));

  //   if (fetchedProducts.length === 0) {
  //     setHasMoreUserProducts(false);
  //   } else {
  //     setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
  //     setLastVisible(productsSnapshot.docs[productsSnapshot.docs.length - 1]);
  //   }
  // }, [product?.uid, lastVisible]);

  const fetchUserProducts = useCallback(async () => {
    if (!product?.uid) return;

    try {
      let productsQuery = query(
        collection(db, 'products'),
        where('uid', '==', product.uid),
        orderBy('createDate'),
        limit(10)
      );

      if (lastVisible) {
        productsQuery = query(productsQuery, startAfter(lastVisible));
      }

      const productsSnapshot = await getDocs(productsQuery);
      const fetchedProducts = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (fetchedProducts.length === 0) {
        setHasMoreUserProducts(false);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
        setLastVisible(productsSnapshot.docs[productsSnapshot.docs.length - 1]);
      }
    } catch (err) {
      if (err.code === 'failed-precondition' || err.code === 'unavailable') {
        console.error('Firestore index error:', err);
        setError(
          '필요한 인덱스가 없습니다. Firestore 콘솔에서 인덱스를 생성하세요.'
        );
      } else {
        console.error('Error fetching user products:', err);
        setError(`Error fetching user products: ${err.message}`);
      }
    }
  }, [product?.uid, lastVisible]);

  useEffect(() => {
    fetchUserProducts();
  }, [fetchUserProducts]);

  useEffect(() => {
    const fetchProductOwnerName = async () => {
      if (product?.uid) {
        const profile = await fetchUserProfile(product.uid);

        setProductOwnerName(profile.displayName);
        setProductOwnerPhotoURL(profile.photoURL);
      }
    };

    fetchProductOwnerName();
  }, [product]);

  useEffect(() => {
    const timerId = setTimeout(addViewsCount, 3000);
    return () => clearTimeout(timerId);
  }, [product, currentUser]);

  const loadMoreUserProducts = () => {
    if (hasMoreUserProducts) {
      setUserProductsPage((prevPage) => prevPage + 1);
    }
  };

  const addViewsCount = async () => {
    if (
      !currentUser ||
      currentUser.uid === product?.uid ||
      product?.viewUsers?.includes(currentUser.uid)
    ) {
      return;
    }

    const updateObject = {
      views: increment(1),
      viewUsers: arrayUnion(currentUser.uid),
    };
    const productRef = doc(db, 'products', productId);

    try {
      await updateDoc(productRef, updateObject);
    } catch (error) {
      console.error('Error updating product views:', error);
      await MessageBoxInform(
        '조회수 동작에 오류가 발생하였습니다. 다시 시도해주세요.'
      );
    }
  };

  const isLoggedIn = async () => {
    if (!currentUser) {
      const isConfirmed = await MessageBoxConfirm(
        t('login-required'),
        t('goto-login')
      );
      if (isConfirmed) {
        navigate('/login');
      }
      return false;
    }
    return true;
  };

  const hasPermission = async (currentUserId, ownerId) => {
    if (currentUserId === ownerId) {
      await MessageBoxInform('본인이 등록한 상품에는 허용되지 않습니다.');
      return false;
    }
    return true;
  };

  const handleLike = async () => {
    if (!(await isLoggedIn())) return;

    if (currentUser.uid === product.uid) {
      MessageBoxInform('본인이 등록한 상품에는 좋아요를 표시할 수 없습니다.');
      return;
    }

    if (product.likeUsers && product.likeUsers.includes(currentUser.uid)) {
      try {
        await removeLikeFromProduct(currentUser.uid);
        await updateMyLikeList(currentUser.uid, productId, false);
        setIsLiked(false);
        dispatch(likeProduct(productId));
      } catch (error) {
        console.error('Failed to remove the like:', error);
        MessageBoxInform('좋아요 취소에 실패하였습니다. 다시 시도해주세요.');
        return;
      }
    } else {
      const updateObject = {
        likes: increment(1),
        likeUsers: arrayUnion(currentUser.uid),
      };

      try {
        await updateLikesToProduct(updateObject, currentUser.uid);
        setIsLiked(true);
        dispatch(likeProduct(productId));
      } catch (error) {
        console.error('Failed to update the product:', error);
        MessageBoxInform(
          '좋아요 업데이트에 실패하였습니다. 다시 시도해주세요.'
        );
        return;
      }

      try {
        await updateMyLikeList(currentUser.uid, productId, true);
      } catch (error) {
        console.error("Failed to add product to user's likes:", error);
        MessageBoxInform(
          '내가 좋아하는 물품 추가에 실패하였습니다. 다시 시도해주세요.'
        );
      }
    }
  };

  const removeLikeFromProduct = async (currentUserId) => {
    if (!(await isLoggedIn())) return;

    const productRef = doc(db, 'products', productId);
    try {
      await updateDoc(productRef, {
        likes: increment(-1),
        likeUsers: arrayRemove(currentUserId),
      });
      console.log('Like removed successfully!');

      setProduct((prevState) => ({
        ...prevState,
        likes: prevState.likes - 1,
        likeUsers: prevState.likeUsers.filter((id) => id !== currentUserId),
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateLikesToProduct = async (updateObject, currentUserId) => {
    if (!isLoggedIn()) return;

    const productRef = doc(db, 'products', productId);
    const productSnapshot = await getDoc(productRef);
    const productData = productSnapshot.data();

    if (
      productData.likeUsers &&
      productData.likeUsers.includes(currentUserId)
    ) {
      console.log('User already liked this product.');
      return;
    }

    try {
      await updateDoc(productRef, updateObject);
      console.log('Document successfully updated!');

      setProduct((prevState) => ({
        ...prevState,
        likes: prevState.likes + 1,
        likeUsers: Array.isArray(prevState.likeUsers)
          ? [...prevState.likeUsers, currentUserId]
          : [currentUserId],
      }));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const updateMyLikeList = async (userId, productId, isAdding) => {
    if (!(await isLoggedIn())) return;

    const userLikesRef = doc(db, 'userLikes', userId);

    try {
      const docSnapshot = await getDoc(userLikesRef);

      if (docSnapshot.exists()) {
        const likesProductIds = docSnapshot.data().products || [];

        if (isAdding) {
          if (!likesProductIds.includes(productId)) {
            likesProductIds.push(productId);
            await updateDoc(userLikesRef, { products: likesProductIds });
          }
        } else {
          const index = likesProductIds.indexOf(productId);
          if (index > -1) {
            likesProductIds.splice(index, 1);
            await updateDoc(userLikesRef, { products: likesProductIds });
          }
        }
      } else if (isAdding) {
        await setDoc(userLikesRef, { products: [productId] });
      }
    } catch (err) {
      console.error('Error handling like list:', err);
    }
  };

  const goToDelete = async () => {
    if (!(await isLoggedIn())) return;

    if (!window.confirm('정말로 이 상품을 삭제하시겠습니까?')) {
      return;
    }

    try {
      const productRef = doc(db, 'products', productId);
      await deleteDoc(productRef);

      const chatroomQuery = query(
        collection(db, 'chatroom'),
        where('productId', '==', productId)
      );
      const chatrooms = await getDocs(chatroomQuery);

      const batch = writeBatch(db);

      chatrooms.forEach((docSnapshot) => {
        batch.delete(docSnapshot.ref);
      });
      await batch.commit();

      const userLikesQuery = query(
        collection(db, 'userLikes'),
        where('products', 'array-contains', productId)
      );
      const usersWithLikes = await getDocs(userLikesQuery);

      for (const docSnapshot of usersWithLikes.docs) {
        const userRef = doc(db, 'userLikes', docSnapshot.id);
        await updateDoc(userRef, {
          products: arrayRemove(productId),
        });
      }

      MessageBoxInform('상품 및 관련된 채팅이 성공적으로 삭제되었습니다.');
      navigate('/');
    } catch (err) {
      console.error('Error deleting product and related chats:', err);
      MessageBoxInform('상품을 삭제하는 도중 오류가 발생했습니다.');
    }
  };

  const goToChat = async () => {
    if (!(await isLoggedIn())) return;

    const productOwnerId = product.uid;

    if (currentUser.uid === productOwnerId) {
      MessageBoxInform('채팅을 할 수 없습니다.');
      return;
    }

    const participants = [currentUser.uid, productOwnerId];

    try {
      const chatroomQuery = query(
        collection(db, 'chatroom'),
        where('productId', '==', productId),
        limit(1)
      );

      const chatroomSnapshot = await getDocs(chatroomQuery);

      const chatroom = chatroomSnapshot.docs.find((doc) => {
        const data = doc.data();
        return data.who.includes(currentUser.uid);
      });

      let chatRoomId;

      if (chatroom) {
        chatRoomId = chatroom.id;
      } else {
        const chatRoomData = {
          who: participants,
          productId: productId,
          productTitle: product.title,
          date: new Date(),
        };

        try {
          const newChatRoomRef = await addDoc(
            collection(db, 'chatroom'),
            chatRoomData
          );
          chatRoomId = newChatRoomRef.id;
        } catch (error) {
          console.error('Error adding document to chatroom:', error);
        }

        const productRef = doc(db, 'products', productId);
        await updateDoc(productRef, {
          chats: increment(1),
        });
      }

      navigate(`/chatview/${productId}/${chatRoomId}`);
    } catch (err) {
      console.error('Error accessing or creating chat room:', err);
      MessageBoxInform('start chat error');
    }
  };
  const handleAvatarClick = () => {
    navigate(`/user_profile/${productOwnerId}`);
  };
  const goToEdit = async () => {
    if (!(await isLoggedIn())) return;

    navigate(`/edit/${productId}`);
  };
  const fileClick = (index) => {
    navigate('/imageviewer', { state: { files: metaFiles, index: index } });
  };
  const onIndexChange = (index) => {
    setActiveImageIndex(index);
  };

  const thumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  return (
    <Box
      p={2}
      sx={{
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
        marginBottom: isMobile ? '120px' : isPad ? '70px' : '70px',
      }}
    >
      {MessageBoxRender()}
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {Array.isArray(product?.xlarge_images) &&
            product.xlarge_images.length > 0 && (
              <ImageSlider
                activeIndex={activeImageIndex}
                images={product.xlarge_images}
                fileClick={fileClick}
                onIndexChange={onIndexChange}
              />
            )}
          <Paper
            variant="outlined"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              p: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: '600px',
              margin: 'auto',
            }}
          >
            {product?.icon_images?.map((image, index) => (
              <IconButton
                key={index}
                onClick={() => setActiveImageIndex(index)}
                sx={{
                  border:
                    index === activeImageIndex ? '2px solid blue' : 'none',
                  width: { xs: 45, sm: 60, md: 80 }, // 반응형 크기 조정
                  height: { xs: 35, sm: 45, md: 60 }, // 반응형 크기 조정
                  borderRadius: 0,
                  padding: '1px',
                  '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    margin: 0,
                  },
                }}
              >
                <img src={`${image}`} alt={`Preview ${index}`} />
              </IconButton>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">
            {t('product.title')}: <strong>{product?.title}</strong>
          </Typography>
          <Typography variant="subtitle1">
            {t('product.content')}: <strong>{product?.content}</strong>
          </Typography>
          <Typography variant="subtitle1">
            {t('product.date')}: <strong>{product?.formattedDate}</strong>
          </Typography>
          {/* <Typography variant="subtitle1">
            {t('product.price')}: <strong>{formatPrice(product?.price)}</strong>
          </Typography> */}
          <Typography variant="subtitle1">
            {/* {activeEvent
              ? `할인가: ${formatPrice(discountedPrice)} 원 (${
                  activeEvent.discount
                }% 할인)`
              : `가격: ${formatPrice(product.price)} 원`} */}

            {activeEvent ? (
              <>
                {t('original-price')}:{' '}
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: '#888',
                  }}
                >
                  {formatPrice(product?.price)}원
                </span>
                <br />
                <span style={{ color: 'red' }}>
                  {`${activeEvent.name} ${activeEvent.discount}% OFF`}
                </span>
                <br />
                {t('discounted-price')}:{' '}
                <strong>{formatPrice(discountedPrice)}원</strong>
              </>
            ) : (
              <>
                {t('price')}: <strong>{formatPrice(product?.price)}원</strong>
              </>
            )}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Grid container justifyContent="flex-start">
              <Avatar
                src={productOwnerPhotoURL}
                alt={productOwnerName}
                sx={{ width: 40, height: 40, mr: 2, borderRadius: '50%' }} // 원형으로 변경
                onClick={handleAvatarClick}
              />

              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {t('seller')}:{productOwnerName}
              </Typography>
            </Grid>
            {productOwnerId && (
              <Grid container justifyContent="flex-end">
                <UserRatings userId={productOwnerId} />
              </Grid>
            )}
          </Box>
          <UserProductPurchase product={product} userId={currentUser?.uid} />
          <Grid container justifyContent="flex-end">
            <IconButton>
              {product.likes} <FavoriteIcon />
            </IconButton>
            <IconButton>
              {product.views} <VisibilityIcon />
            </IconButton>
            <IconButton>
              {product.chats} <ChatIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h6">
          {productOwnerName}
          {t('s-products')} ({products.length} {t('items')})
        </Typography>
        <Grid container spacing={2}>
          {products.length === 0 ? (
            <Grid item xs={12}>
              <Typography textAlign="center">{t('no-products')}</Typography>
            </Grid>
          ) : isMobile ? (
            <Box
              id="parentScrollBox"
              sx={{
                marginTop: '20px',
                height: 'calc(80vh - 70px)',
                overflow: 'auto',
              }}
            >
              <InfiniteScroll
                dataLength={products.length}
                next={loadMoreUserProducts}
                hasMore={hasMoreUserProducts}
                loader={<Typography>{t('loading')}</Typography>}
                endMessage={
                  <Typography variant="body2">{t('noMoreProducts')}</Typography>
                }
                scrollableTarget="parentScrollBox"
              >
                <Grid container spacing={2}>
                  {products.map((product) => (
                    <Grid item xs={12} key={product.id}>
                      <Product
                        product={product}
                        onClick={() => handleProductClick(product)}
                        horizontal={isMobile} // pass the prop for mobile view
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          ) : (
            products.map((product) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={product.id}>
                <Product
                  key={product.id}
                  product={product}
                  onClick={() => handleProductClick(product)}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>

      <SimilarUserProducts productId={product?.id} />

      <Box
        sx={{
          position: 'fixed',
          bottom: isMobile ? 50 : isPad ? 0 : 0,
          left: 0,
          width: '100%',
          bgcolor: 'background.paper',
          px: 2,
          py: 1,
          boxShadow: 1,
          zIndex: 1000,
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          {currentUser && currentUser.uid === productOwnerId ? (
            <React.Fragment>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={goToEdit}
                  fullWidth
                >
                  {t('edit')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={goToDelete}
                  fullWidth
                >
                  {t('delete')}
                </Button>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={6}>
                <Button onClick={handleLike} fullWidth>
                  {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  {t('like')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button startIcon={<ChatIcon />} onClick={goToChat} fullWidth>
                  {t('chat')}
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default Detail;
