import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firebase Firestore 설정 파일

export const fetchEventData = async () => {
  const eventCollection = collection(db, 'events');
  const eventSnapshot = await getDocs(eventCollection);
  const eventData = eventSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
  return eventData;
};
