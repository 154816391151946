import i18n from 'i18next'; // i18next에서 현재 언어 가져오기
const baseUrl = "https://api.openweathermap.org/data/2.5";
const onecallUrl = "https://api.openweathermap.org/data/2.5/onecall";

export const fetchWeatherData = async (city: string | { lat: number; lng: number }) => {
  let url = `${baseUrl}/weather?q=${city}&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;

  const lang = i18n.language;
  if (typeof city === 'object') {
    url = `${baseUrl}/weather?lat=${city.lat}&lon=${city.lng}&lang=${lang}&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
  }
  return await (await fetch(url)).json();
};


export const fetchDayForecastData = async (city: string | { lat: number; lng: number }) => {
  const onecallUrl = "https://api.openweathermap.org/data/2.5/onecall";
  let url = `${baseUrl}/forecast?q=London&units=metric&cnt=7&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
  const lang = i18n.language;
  if (typeof city === 'object') {
    // url = `${baseUrl}/forecast/daily?lat=${city.lat}&lon=${city.lng}&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
    //url = `${baseUrl}/forecast/daily?id=500&&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
    
    // url = `${onecallUrl}/forecast?lat=${city.lat}&lon=${city.lng}&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
    // url = `${onecallUrl}?lat=${city.lat}&lon=${city.lng}&exclude=minutely,hourly&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
    url = `${baseUrl}/forecast?lat=${city.lat}&lon=${city.lng}&lang=${lang}&cnt=7&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
  }

  return await (await fetch(url)).json();
};


// 새로운 시간별 예보 가져오기 함수
export const fetchTimeForecastData = async (city: string | { lat: number; lng: number }) => {
  let url = `${baseUrl}/forecast?q=London&units=metric&cnt=7&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
  if (typeof city === 'object') {
    url = `${onecallUrl}?lat=${city.lat}&lon=${city.lng}&exclude=current,minutely,daily&units=metric&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;
  }
  // try {
  //   const response = await fetch(url);
  //   if (!response.ok) {
  //     throw new Error(`Failed to fetch time weather data: ${response.statusText}`);
  //   }
  //   // const data = await response.json();
  //   // return data.hourly; // 시간별 예보 데이터만 반환
  //   return await (await fetch(url)).json();
  // } catch (error) {
  //   console.error("Error fetching time weather data:", error);
  //   return null;
  // }

  return await (await fetch(url)).json();
};