// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
//   Card,
//   CardContent,
//   CardMedia,
// } from '@mui/material';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import axios from 'axios';

// function MyTownWeather() {
//   const [loading, setLoading] = useState(true);
//   const [locationName, setLocationName] = useState('');
//   const [weatherData, setWeatherData] = useState(null);
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             fetchWeather(locationData.lat, locationData.lng);
//           }
//         }
//       }
//     };

//     //     const fetchWeather = async (lat, lon) => {
//     //       try {
//     //         const apiKey = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
//     //         const response = await axios.get(
//     //           `https://api.openweathermap.org/data/2.5/weather`,
//     //           {
//     //             params: {
//     //               lat,
//     //               lon,
//     //               units: 'metric',
//     //               appid: apiKey,
//     //             },
//     //           }
//     //         );

//     //         setWeatherData(response.data);
//     //       } catch (error) {
//     //         console.error('Failed to fetch weather data', error);
//     //       } finally {
//     //         setLoading(false);
//     //       }
//     //     };

//     //     fetchLocation();
//     //   }, [currentUser]);

//     const fetchWeather = async (latitude, longitude) => {
//       try {
//         const serverUrl =
//           'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
//         const response = await axios.post(serverUrl, {
//           myLocation: { latitude, longitude },
//         });
//         setWeatherData(response.data);
//       } catch (error) {
//         console.error('Failed to fetch weather data', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchLocation();
//   }, [currentUser]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container
//       maxWidth="sm"
//       sx={{
//         height: '30vh',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <Typography variant="h5" component="h1" gutterBottom>
//         {locationName ? `내 동네: ${locationName}` : '내 동네를 설정해주세요'}
//       </Typography>
//       {/* {weatherData && (
//         <Card sx={{ display: 'flex', marginTop: 2 }}>
//           <CardMedia
//             component="img"
//             sx={{ width: 151 }}
//             image={`http://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
//             alt="Weather icon"
//           />
//           <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//             <CardContent sx={{ flex: '1 0 auto' }}>
//               <Typography component="div" variant="h5">
//                 {weatherData.main.temp}°C
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 {weatherData.weather[0].description}
//               </Typography>
//             </CardContent>
//           </Box>
//         </Card>
//       )} */}
//       {weatherData && (
//         <Card sx={{ display: 'flex', marginTop: 2 }}>
//           <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//             <CardContent sx={{ flex: '1 0 auto' }}>
//               <Typography component="div" variant="h5">
//                 온도: {weatherData.temperature}
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 습도: {weatherData.humidity}
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 하늘 상태: {weatherData.skyCondition}
//               </Typography>
//               <Typography
//                 variant="subtitle1"
//                 color="text.secondary"
//                 component="div"
//               >
//                 강수량: {weatherData.rainfall}
//               </Typography>
//             </CardContent>
//           </Box>
//         </Card>
//       )}

//       <Button
//         variant="contained"
//         color="primary"
//         onClick={() => navigate('/settings/mytown')}
//       >
//         동네 설정하기
//       </Button>
//     </Container>
//   );
// }

// export default MyTownWeather;

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
//   Card,
//   CardContent,
// } from '@mui/material';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import axios from 'axios';
// import { keyframes } from '@emotion/react';
// import styled from '@emotion/styled';

// const scroll = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// `;

// const ScrollingBar = styled(Typography)`
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden;
//   animation: ${scroll} 10s linear infinite;
// `;

// function MyTownWeather() {
//   const [loading, setLoading] = useState(false);
//   const [locationName, setLocationName] = useState('');
//   const [weatherData, setWeatherData] = useState(null);
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             fetchWeather(locationData.lat, locationData.lng);
//           }
//         }
//       }
//     };

//     const fetchWeather = async (latitude, longitude) => {
//       setLoading(true);
//       try {
//         const serverUrl =
//           'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
//         const response = await axios.post(serverUrl, {
//           myLocation: { latitude, longitude },
//         });
//         setWeatherData(response.data);
//       } catch (error) {
//         console.error('Failed to fetch weather data', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (currentUser) {
//       fetchLocation(); // Fetch only when user is logged in
//     }
//   }, [currentUser]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container
//       maxWidth="sm"
//       sx={{
//         height: '30vh',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <Typography variant="h5" component="h1" gutterBottom>
//         {locationName ? `내 동네: ${locationName}` : '내 동네를 설정해주세요'}
//       </Typography>
//       {weatherData && (
//         <Box
//           sx={{
//             width: '100%',
//             overflow: 'hidden',
//             whiteSpace: 'nowrap',
//             border: '1px solid #ccc',
//             padding: '10px',
//             marginTop: 2,
//           }}
//         >
//           <ScrollingBar variant="h6">
//             온도: {weatherData.temperature} | 습도: {weatherData.humidity} |
//             하늘 상태: {weatherData.skyCondition} | 강수량:{' '}
//             {weatherData.rainfall}
//           </ScrollingBar>
//         </Box>
//       )}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={() => navigate('/settings/mytown')}
//         sx={{ marginTop: 2 }}
//       >
//         동네 설정하기
//       </Button>
//     </Container>
//   );
// }

// export default MyTownWeather;
// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
// } from '@mui/material';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import axios from 'axios';
// import { keyframes } from '@emotion/react';
// import styled from '@emotion/styled';
// import sunny from '../assets/images/weather/sunny.jpg';
// import rain from '../assets/images/weather/rain.jpg';
// import cloud from '../assets/images/weather/cloud.jpg';
// import snow from '../assets/images/weather/snow.jpg';
// // Keyframes for the scrolling text
// const scroll = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// `;

// // Styled component for the scrolling text
// const ScrollingBar = styled(Typography)`
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden;
//   animation: ${scroll} 10s linear infinite;
// `;

// // Styled component for the background container with dynamic background image
// const BackgroundBox = styled(Box)`
//   background-image: ${(props) => props.backgroundImage};
//   background-size: cover;
//   background-position: center;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// function MyTownWeather() {
//   const [loading, setLoading] = useState(false);
//   const [locationName, setLocationName] = useState('');
//   const [weatherData, setWeatherData] = useState(null);
//   const [backgroundImage, setBackgroundImage] = useState(); // Default to sunny
//   const [clothingAdvice, setClothingAdvice] = useState('');
//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             fetchWeather(locationData.lat, locationData.lng);
//           }
//         }
//       }
//     };

//     const fetchWeather = async (latitude, longitude) => {
//       setLoading(true);
//       try {
//         const serverUrl =
//           'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
//         const response = await axios.post(serverUrl, {
//           myLocation: { latitude, longitude },
//         });
//         const weather = response.data;
//         setWeatherData(weather);
//         updateBackgroundAndClothing(weather);
//       } catch (error) {
//         console.error('Failed to fetch weather data', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const updateBackgroundAndClothing = (weather) => {
//       const skyCondition = weather.skyCondition.toLowerCase();
//       const tempString = weather.temperature; // Example: '28도'

//       // Extract the numeric part from the string
//       const temp = parseInt(tempString, 10); // Extract '28' from '28도'

//       // Update background image based on weather conditions
//       if (skyCondition.includes('clear') || skyCondition.includes('맑음')) {
//         setBackgroundImage(`url(${sunny})`); // Use sunny image
//       } else if (
//         skyCondition.includes('cloud') ||
//         skyCondition.includes('구름')
//       ) {
//         setBackgroundImage(`url(${cloud})`);
//       } else if (skyCondition.includes('rain') || skyCondition.includes('비')) {
//         setBackgroundImage(`url(${rain})`);
//       } else if (skyCondition.includes('snow') || skyCondition.includes('눈')) {
//         setBackgroundImage(`url(${snow})`);
//       } else {
//         setBackgroundImage(`url(${cloud})`); // Default to sunny image if unknown condition
//       }

//       // Update clothing advice based on temperature
//       if (temp > 25) {
//         setClothingAdvice("It's hot outside! Wear light clothing.");
//       } else if (temp > 15) {
//         setClothingAdvice(
//           'The weather is mild. A light jacket is recommended.'
//         );
//       } else if (temp > 5) {
//         setClothingAdvice("It's getting chilly. Wear a warm jacket.");
//       } else {
//         setClothingAdvice("It's very cold! Bundle up in a coat and scarf.");
//       }

//       if (skyCondition.includes('rain')) {
//         setClothingAdvice((prev) => `${prev} Don't forget an umbrella!`);
//       }
//     };

//     if (currentUser) {
//       fetchLocation(); // Fetch only when user is logged in
//     }
//   }, [currentUser]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <BackgroundBox backgroundImage={backgroundImage}>
//       <Container
//         maxWidth="sm"
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           color: '#fff', // Adjust text color to be visible on all backgrounds
//         }}
//       >
//         <Typography variant="h5" component="h1" gutterBottom>
//           {locationName ? `내 동네: ${locationName}` : '내 동네를 설정해주세요'}
//         </Typography>
//         {weatherData && (
//           <>
//             <Box
//               sx={{
//                 width: '100%',
//                 overflow: 'hidden',
//                 whiteSpace: 'nowrap',
//                 border: '1px solid #ccc',
//                 padding: '10px',
//                 marginTop: 2,
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
//               }}
//             >
//               <ScrollingBar variant="h6">
//                 온도: {weatherData.temperature} | 습도: {weatherData.humidity} |
//                 하늘 상태: {weatherData.skyCondition} | 강수량:{' '}
//                 {weatherData.rainfall}
//               </ScrollingBar>
//             </Box>
//             <Typography variant="h6" sx={{ marginTop: 2 }}>
//               {clothingAdvice}
//             </Typography>
//           </>
//         )}
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => navigate('/settings/mytown')}
//           sx={{ marginTop: 2 }}
//         >
//           동네 설정하기
//         </Button>
//       </Container>
//     </BackgroundBox>
//   );
// }

// export default MyTownWeather;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import axios from 'axios';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next'; // Import i18next hook
import sunny from '../assets/images/weather/sunny.jpg';
import rain from '../assets/images/weather/rain.jpg';
import cloud from '../assets/images/weather/cloud.jpg';
import snow from '../assets/images/weather/snow.jpg';

import { fetchWeather } from './fetchWeather';
import { setIsLoading, setWeatherData } from '../Redux/reducers/appReducer';
import Forecast from './Forecast/DayForecast';
import Header from './Header/Header';
import Search from './Search/Search';
import Spinner from './ui/Spinner/Spinner';
import CurrentWeather from './CurrentWeather/CurrentWeather';
import { AppStore } from '../Redux/store';
// Keyframes for the scrolling text
const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// Styled component for the scrolling text
const ScrollingBar = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: ${scroll} 10s linear infinite;
`;

// Styled component for the background container with dynamic background image
const BackgroundBox = styled(Box)`
  background-image: ${(props) => props.backgroundImage};
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function MyTownWeather() {
  const [loading, setLoading] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [weatherData, setWeatherData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(); // Default to sunny
  const [clothingAdvice, setClothingAdvice] = useState('');
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t, i18n } = useTranslation(); // Use the translation hook

  useEffect(() => {
    const fetchLocation = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const selectedTown = userData.selectedTown;
          const locationData =
            selectedTown === 1 ? userData.town1 : userData.town2;
          if (locationData) {
            setLocationName(locationData.name);
            fetchWeather(locationData.lat, locationData.lng);
          }
        }
      }
    };

    const fetchWeather = async (latitude, longitude) => {
      setLoading(true);
      try {
        const serverUrl =
          'https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getWeatherByLocation';
        const response = await axios.post(serverUrl, {
          myLocation: { latitude, longitude },
        });
        const weather = response.data;
        setWeatherData(weather);
        updateBackgroundAndClothing(weather);
      } catch (error) {
        console.error('Failed to fetch weather data', error);
      } finally {
        setLoading(false);
      }
    };

    const updateBackgroundAndClothing = (weather) => {
      const skyCondition = weather.skyCondition.toLowerCase();
      const tempString = weather.temperature; // Example: '28도'

      // Extract the numeric part from the string
      const temp = parseInt(tempString, 10); // Extract '28' from '28도'

      // Update background image based on weather conditions
      if (skyCondition.includes('clear') || skyCondition.includes('맑음')) {
        setBackgroundImage(`url(${sunny})`); // Use sunny image
      } else if (
        skyCondition.includes('cloud') ||
        skyCondition.includes('구름')
      ) {
        setBackgroundImage(`url(${cloud})`);
      } else if (skyCondition.includes('rain') || skyCondition.includes('비')) {
        setBackgroundImage(`url(${rain})`);
      } else if (skyCondition.includes('snow') || skyCondition.includes('눈')) {
        setBackgroundImage(`url(${snow})`);
      } else {
        setBackgroundImage(`url(${cloud})`); // Default to cloud image if unknown condition
      }

      // Update clothing advice based on temperature
      if (temp > 25) {
        setClothingAdvice(t('weather.hot'));
      } else if (temp > 15) {
        setClothingAdvice(t('weather.mild'));
      } else if (temp > 5) {
        setClothingAdvice(t('weather.chilly'));
      } else {
        setClothingAdvice(t('weather.cold'));
      }

      if (skyCondition.includes('rain')) {
        setClothingAdvice((prev) => `${prev} ${t('weather.umbrella')}`);
      }
    };

    if (currentUser) {
      fetchLocation(); // Fetch only when user is logged in
    }
  }, [currentUser, t]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <BackgroundBox backgroundImage={backgroundImage}>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff', // Adjust text color to be visible on all backgrounds
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          {locationName ? `${locationName}` : t('weather.setLocation')}
        </Typography>
        {weatherData && (
          <>
            <Box
              sx={{
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                border: '1px solid #ccc',
                padding: '10px',
                marginTop: 2,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <ScrollingBar variant="h6">
                온도: {weatherData.temperature} | 습도: {weatherData.humidity} |
                하늘 상태: {weatherData.skyCondition} | 강수량:{' '}
                {weatherData.rainfall}
              </ScrollingBar>
            </Box>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {clothingAdvice}
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/settings/mytown')}
          sx={{ marginTop: 2 }}
        >
          {t('myTown.title')}
        </Button>
      </Container>
    </BackgroundBox>
  );
}

export default MyTownWeather;
