
import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../Redux/store';
import ForecastItem from './ForecastItem';
import { ForecastContainer, ForecastItems, SectionTitle } from './styled';
import { useTranslation } from 'react-i18next';

const DayForecast: React.FC = () => {
  const { t } = useTranslation();
  const { forecast, isInitial } = useSelector((state: AppStore) => ({
    loading: state.app.isLoading,
    isInitial: state.app.isInitial,
    forecast: state.weather.dayWeatherData,
  }));

  if (isInitial) return <></>;

  return (
    <ForecastContainer>
      <SectionTitle>{t('weather.forecast.title')}</SectionTitle>
      <ForecastItems>
        {forecast.map((item, i) => {
          return (
            <ForecastItem
            key={i}
            day={item.day}
            high={item.temp.temp_max}
            low={item.temp.temp_min}
            weatherCode={item.weather.id}
            main={item.weather.main}
            />
          );
        })}
      </ForecastItems>
    </ForecastContainer>
  );
};

export default DayForecast;
