import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, IconButton, Collapse } from '@mui/material';
import ProductCategorySelector from './Stores/ProductCategorySelector';
import ProductBrandSelector from './Stores/ProductBrandSelector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CategoryBrandSelector = ({
  onSelectCategory,
  onSelectBrand,
  selectedCategory,
  selectedBrand,
}) => {
  const searchBrand = useSelector((state) => state.search.searchBrand);
  const searchCategory = useSelector((state) => state.search.searchCategory);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (searchCategory) {
      onSelectCategory(searchCategory);
      navigate('/category_search_results');
    }
  }, [searchCategory]);

  useEffect(() => {
    if (searchBrand) {
      onSelectBrand(searchBrand);
      navigate('/brand_search_results');
    }
  }, [searchBrand]);

  const handleSelectCategory = (category) => {
    onSelectCategory(category);
    navigate('/category_search_results');
  };

  const handleSelectBrand = (brand) => {
    onSelectBrand(brand);
    navigate('/brand_search_results');
  };

  const toggleVisibility = () => {
    setVisible((prev) => !prev);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          top: 0,
          zIndex: 1,
        }}
      >
        <IconButton
          onClick={toggleVisibility}
          sx={{
            width: '30px',
            height: '10px',
            padding: 0,
            backgroundColor: '#e0e0e0',
            color: '#000',
            border: '1px solid #ccc',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#d0d0d0',
            },
          }}
        >
          {visible ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <Collapse in={visible}>
        <Box sx={{ width: '100%', backgroundColor: '#f0f0f0', py: 2, mt: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <ProductCategorySelector
                categoryId={selectedCategory?.id}
                onSelectCategory={handleSelectCategory}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProductBrandSelector
                brandId={selectedBrand?.id}
                onSelectBrand={handleSelectBrand}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CategoryBrandSelector;
