// import React, { useEffect } from 'react';
// import {
//   Card,
//   CardActionArea,
//   CardMedia,
//   CardContent,
//   Typography,
//   IconButton,
//   Box,
//   Chip,
//   Tooltip,
//   useMediaQuery,
// } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import ChatIcon from '@mui/icons-material/Chat';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { formatPrice } from './Utils/PriceFormatter';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import productDefaultImage from './assets/images/productDefaultImage.png';
// import { formatState } from './Utils/ProductState';

// const Product = ({ product, onClick, horizontal }) => {
//   const { t, i18n } = useTranslation();
//   useEffect(() => {
//     moment.locale(i18n.language);
//   }, [i18n.language]);
//   const dateObj = product?.createDate?.toDate();
//   const formattedDate = moment(dateObj).fromNow();

//   // const formatState = (state) => {
//   //   switch (state) {
//   //     case 0:
//   //       return {
//   //         icon: <CheckCircleIcon />,
//   //         text: t('selling'),
//   //         color: 'success',
//   //       };
//   //     case 1:
//   //       return { icon: <FavoriteIcon />, text: t('reserved'), color: 'info' };
//   //     case 2:
//   //       return { icon: <ChatIcon />, text: t('sold-out'), color: 'secondary' };
//   //     default:
//   //       return { icon: <CancelIcon />, text: t('unknown'), color: 'error' };
//   //   }
//   // };
//   const imageUrl =
//     product.icon_images && product.icon_images.length > 0
//       ? product.icon_images[0]
//       : productDefaultImage;

//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

//   const productState = formatState(product.state, t);
//   return (
//     <Card
//       sx={{
//         maxWidth: '100%',
//         m: '5px',
//         p: '5px',
//         boxShadow: 3,
//         borderRadius: 2,
//         overflow: 'hidden',
//         '@media (max-width: 600px)': {
//           m: '2px',
//           p: '2px',
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardActionArea>
//         {horizontal && isMobile ? (
//           <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
//             <Box
//               sx={{
//                 width: '40%',
//                 height: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <CardMedia
//                 component="img"
//                 image={imageUrl}
//                 alt={product.name}
//                 sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
//               />
//             </Box>
//             <CardContent
//               sx={{
//                 width: '60%',
//                 padding: '8px',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 marginTop: 'auto',
//                 marginBottom: 'auto',
//               }}
//             >
//               <Typography gutterBottom variant="h6" component="div" noWrap>
//                 {product.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" noWrap>
//                 {product.content}
//               </Typography>

//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 0,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={productState.icon}
//                   label={productState.text}
//                   color={productState.color}
//                   size="small"
//                   variant="outlined"
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </Box>
//         ) : (
//           <>
//             {/* <CardMedia
//               component="img"
//               height="200"
//               image={imageUrl}
//               alt={product.name}
//               sx={{ objectFit: 'cover' }}
//             /> */}
//             <CardMedia
//               component={RouterLink}
//               // to={`/product_detail/${product.uid}/${product.id}`}
//               sx={{
//                 height: 0,
//                 paddingTop: '56.25%',
//                 backgroundImage: `url(${imageUrl})`,
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center',
//                 borderRadius: '8px',
//                 '@media (max-width: 600px)': {
//                   height: '150px',
//                   paddingTop: 0,
//                 },
//               }}
//               alt={product.productName}
//             />
//             <CardContent sx={{ padding: '8px' }}>
//               <Typography gutterBottom variant="h6" component="div" noWrap>
//                 {product.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary" noWrap>
//                 {product.content}
//               </Typography>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 1,
//                   mb: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   component="span"
//                 >
//                   {formattedDate}
//                 </Typography>
//                 <Chip
//                   icon={productState.icon}
//                   label={productState.text}
//                   color={productState.color}
//                   size="small"
//                   variant="outlined"
//                   sx={{ fontSize: '0.75rem', height: '20px' }}
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   mt: 2,
//                 }}
//               >
//                 <Typography
//                   variant="body1"
//                   component="span"
//                   sx={{ flexGrow: 1 }}
//                 >
//                   {formatPrice(product.price)} 원
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Tooltip title="Likes">
//                     <IconButton aria-label="add to favorites" size="small">
//                       <FavoriteIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.likes}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Views">
//                     <IconButton aria-label="view count" size="small">
//                       <VisibilityIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.views}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Chat">
//                     <IconButton aria-label="chat count" size="small">
//                       <ChatIcon />
//                       <Typography variant="caption" sx={{ ml: 0.5 }}>
//                         {product.chats}
//                       </Typography>
//                     </IconButton>
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </CardContent>
//           </>
//         )}
//       </CardActionArea>
//     </Card>
//   );
// };

// export default Product;

import React, { useEffect, useState } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Box,
  Chip,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatPrice } from './Utils/PriceFormatter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import productDefaultImage from './assets/images/productDefaultImage.png';
import { formatState } from './Utils/ProductState';
import Ribbon from './Weather/Ribbon'; // Assume we have a ribbon component for display
import { useSelector } from 'react-redux';
import { AppStore } from './Redux/store';
import { fetchEventData } from './api/fetchEventData'; // Firestore에서 이벤트를 가져오는 함수

const Product = ({ product, onClick, horizontal }) => {
  const { t, i18n } = useTranslation();
  const currentWeather = useSelector((state) => state.weather.weatherData);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    moment.locale(i18n.language);
    const fetchData = async () => {
      const events = await fetchEventData();
      setEventData(events);
    };
    fetchData();
  }, [i18n.language]);

  // const checkEventCondition = (event, currentWeather) => {
  //   switch (event.nameKey) {
  //     case 'rainyDayDiscount':
  //       return currentWeather.rainfall > event.condition; // 비가 100mm 이상일 때
  //     case 'sunnyDaySale':
  //       return currentWeather.main === 'clear'; // 맑은 날씨일 때
  //     case 'snowyDaySpecial':
  //       // return currentWeather.snow && currentWeather.snow['1h'] > 5; // 눈이 시간당 5mm 이상일 때
  //       return currentWeather.wind.speed > 1; // 눈이 많이 내릴 때
  //     case 'heatwaveSavings':
  //       return currentWeather.main.temp > 20; // 기온이 30도 이상일 때
  //     default:
  //       return false; // 기본값으로 조건이 없는 경우
  //   }
  // };

  const checkEventCondition = (event, currentWeather) => {
    // Ensure currentWeather is valid and is an object
    if (!currentWeather || typeof currentWeather !== 'object') {
      console.error('Invalid currentWeather object:', currentWeather);
      return false;
    }

    // Ensure event condition (thresholds) are present
    const eventCondition = event.condition;
    if (!eventCondition) {
      console.error('Event condition not found:', event);
      return false;
    }

    // Switch case for event conditions using dynamic thresholds from the event object
    switch (event.nameKey) {
      case 'rainyDayDiscount':
        // Use dynamic rain threshold from the event
        const rainThreshold = eventCondition ?? 5; // default to 5 if undefined
        return currentWeather.rain?.['1h'] > rainThreshold;

      case 'sunnyDaySale':
        // No thresholds needed, just check weather
        return currentWeather.weather?.[0]?.main === 'Clear';

      case 'snowyDaySpecial':
        // Use dynamic snow threshold from the event
        const snowThreshold = eventCondition ?? 5; // default to 5 if undefined
        return currentWeather.snow?.['1h'] > snowThreshold;

      case 'heatwaveSavings':
        // Use dynamic heatwave temperature threshold from the event
        const heatwaveTempThreshold = eventCondition ?? 35; // default to 35 if undefined
        return currentWeather.main?.temp > heatwaveTempThreshold;

      default:
        console.warn(`Unknown event condition: ${event.nameKey}`);
        return false;
    }
  };
  const dateObj = product?.createDate?.toDate();
  const formattedDate = moment(dateObj).fromNow();
  const imageUrl =
    product.icon_images && product.icon_images.length > 0
      ? product.icon_images[0]
      : productDefaultImage;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const productState = formatState(product.state, t);

  // Check for matching weather-based event from Firestore eventData
  const activeEvent = eventData.find(
    (event) =>
      checkEventCondition(event, currentWeather) &&
      product.event?.id === event.id
  );

  const discountedPrice = activeEvent
    ? product.price * (1 - activeEvent.discount / 100)
    : product.price;

  return (
    <Card
      sx={{
        maxWidth: '100%',
        m: '5px',
        p: '5px',
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        '@media (max-width: 600px)': {
          m: '2px',
          p: '2px',
        },
      }}
      onClick={onClick}
    >
      <CardActionArea>
        {activeEvent && (
          <Ribbon
            text={`${t(activeEvent.name)} ${activeEvent.discount}% OFF`}
            color="secondary"
          />
        )}
        {horizontal && isMobile ? (
          <Box display="flex" flexDirection="row" sx={{ height: '170px' }}>
            <Box
              sx={{
                width: '40%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CardMedia
                component="img"
                image={imageUrl}
                alt={product.name}
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <CardContent
              sx={{
                width: '60%',
                padding: '8px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Typography gutterBottom variant="h6" component="div" noWrap>
                {product.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {product.content}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 0,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  {formattedDate}
                </Typography>
                <Chip
                  icon={productState.icon}
                  label={productState.text}
                  color={productState.color}
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 1,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ flexGrow: 1 }}
                >
                  {formatPrice(discountedPrice)} 원
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Likes">
                    <IconButton aria-label="add to favorites" size="small">
                      <FavoriteIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.likes}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Views">
                    <IconButton aria-label="view count" size="small">
                      <VisibilityIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.views}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat">
                    <IconButton aria-label="chat count" size="small">
                      <ChatIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.chats}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </Box>
        ) : (
          <>
            <CardMedia
              component={RouterLink}
              to={`/product_detail/${product.uid}/${product.id}`}
              sx={{
                height: 0,
                paddingTop: '56.25%',
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                '@media (max-width: 600px)': {
                  height: '150px',
                  paddingTop: 0,
                },
              }}
              alt={product.productName}
            />
            <CardContent sx={{ padding: '8px' }}>
              <Typography gutterBottom variant="h6" component="div" noWrap>
                {product.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {product.content}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 1,
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                >
                  {formattedDate}
                </Typography>
                <Chip
                  icon={productState.icon}
                  label={productState.text}
                  color={productState.color}
                  size="small"
                  variant="outlined"
                  sx={{ fontSize: '0.75rem', height: '20px' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 2,
                }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ flexGrow: 1 }}
                >
                  {formatPrice(discountedPrice)} 원
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Likes">
                    <IconButton aria-label="add to favorites" size="small">
                      <FavoriteIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.likes}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Views">
                    <IconButton aria-label="view count" size="small">
                      <VisibilityIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.views}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat">
                    <IconButton aria-label="chat count" size="small">
                      <ChatIcon />
                      <Typography variant="caption" sx={{ ml: 0.5 }}>
                        {product.chats}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </>
        )}
      </CardActionArea>
    </Card>
  );
};

export default Product;
