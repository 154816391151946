// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

// import {
//   Container,
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
// } from '@mui/material';

// import { doc, getDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig.js';
// import axios from 'axios';
// import { keyframes } from '@emotion/react';
// import styled from '@emotion/styled';
// import { useTranslation } from 'react-i18next'; // Import i18next hook
// import sunny from '../assets/images/weather/sunny.jpg';
// import rain from '../assets/images/weather/rain.jpg';
// import cloud from '../assets/images/weather/cloud.jpg';
// import snow from '../assets/images/weather/snow.jpg';

// import { fetchWeather } from './fetchWeather.ts';
// import { setIsLoading, setWeatherData } from '../Redux/reducers/appReducer.ts';
// import DayForecast from './Forecast/DayForecast.tsx';
// import TimeForecast from './Forecast/TimeForecast.tsx';
// import Header from './Header/Header.tsx';
// import Search from './Search/Search.tsx';
// import Spinner from './ui/Spinner/Spinner.tsx';
// import CurrentWeather from './CurrentWeather/CurrentWeather.tsx';
// import { AppStore } from '../Redux/store.ts';

// // Keyframes for the scrolling text
// const scroll = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// `;

// // Styled component for the scrolling text
// const ScrollingBar = styled(Typography)`
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden;
//   animation: ${scroll} 10s linear infinite;
// `;

// // Styled component for the background container with dynamic background image
// const BackgroundBox = styled(Box)`
//   background-image: ${(props) => props.backgroundImage};
//   background-size: cover;
//   background-position: center;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// function MyWorldWeather() {
//   const [locationName, setLocationName] = useState('');
//   const [backgroundImage, setBackgroundImage] = useState(); // Default to sunny

//   const navigate = useNavigate();
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const { t, i18n } = useTranslation(); // Use the translation hook
//   const dispatch = useDispatch();
//   const { loading } = useSelector((state) => ({
//     loading: state.app.isLoading,
//   }));
//   // const { loading } = useSelector((state: AppStore) => ({
//   //   loading: state.app.isLoading,
//   // }));
//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             dispatch(
//               fetchWeather({
//                 lat: locationData.lat,
//                 lng: locationData.lng,
//               })
//             );
//           }
//         }
//       }
//     };

//     if (currentUser) {
//       fetchLocation(); // Fetch only when user is logged in
//     }
//   }, [currentUser, t]);

//   if (loading) {
//     return (
//       <Container maxWidth="sm">
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <>
//       {/* <BackgroundBox backgroundImage={backgroundImage}> */}
//       <Container
//         maxWidth="sm"
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           // color: '#fff', // Adjust text color to be visible on all backgrounds
//         }}
//       >
//         <Typography variant="h5" component="h1" gutterBottom>
//           {locationName ? `${locationName}` : t('weather.setLocation')}
//         </Typography>

//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => navigate('/settings/mytown')}
//           sx={{ marginTop: 2 }}
//         >
//           {t('myTown.title')}
//         </Button>
//       </Container>
//       <Header />
//       <CurrentWeather />
//       <TimeForecast />
//       <DayForecast />
//       {/* </BackgroundBox> */}
//     </>
//   );
// }

// export default MyWorldWeather;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import axios from 'axios';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next'; // Import i18next hook
import sunny from '../assets/images/weather/sunny.jpg';
import rain from '../assets/images/weather/rain.jpg';
import cloud from '../assets/images/weather/cloud.jpg';
import snow from '../assets/images/weather/snow.jpg';

import { fetchWeather } from './fetchWeather.ts';
import { setIsLoading, setWeatherData } from '../Redux/reducers/appReducer.ts';
import DayForecast from './Forecast/DayForecast.tsx';
import TimeForecast from './Forecast/TimeForecast.tsx';
import Header from './Header/Header.tsx';
import Search from './Search/Search.tsx';
import Spinner from './ui/Spinner/Spinner.tsx';
import CurrentWeather from './CurrentWeather/CurrentWeather.tsx';
import { AppStore } from '../Redux/store.ts';
import MyLocationIcon from '@mui/icons-material/MyLocation';
// Keyframes for the scrolling text
const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// Styled component for the scrolling text
const ScrollingBar = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: ${scroll} 10s linear infinite;
`;

// Styled component for the background container with dynamic background image
const BackgroundBox = styled(Box)`
  background-image: ${(props) => props.backgroundImage};
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// function MyWorldWeather() {
//   const [locationName, setLocationName] = useState('');
//   const navigate = useNavigate();
//   const { t, i18n } = useTranslation(); // Use the translation hook
//   const [backgroundImage, setBackgroundImage] = useState(); // Default to sunny
//   const currentUser = useSelector((state) => state.auth.currentUser);

//   const dispatch = useDispatch();
//   const { loading } = useSelector((state) => ({
//     loading: state.app.isLoading,
//   }));

//   useEffect(() => {
//     const fetchLocation = async () => {
//       if (currentUser) {
//         const userRef = doc(db, 'users', currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           const selectedTown = userData.selectedTown;
//           const locationData =
//             selectedTown === 1 ? userData.town1 : userData.town2;
//           if (locationData) {
//             setLocationName(locationData.name);
//             dispatch(
//               fetchWeather({
//                 lat: locationData.lat,
//                 lng: locationData.lng,
//               })
//             );
//           }
//         }
//       }
//     };

//     if (currentUser) {
//       fetchLocation(); // Fetch only when user is logged in
//     }
//   }, [currentUser, t]);
//   return (
//     <>
//       <Container
//         maxWidth="sm"
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           textAlign: 'center', // 텍스트와 버튼을 가운데 정렬
//           padding: '20px', // 전체 여백 추가
//         }}
//       >
//         <Typography variant="h5" component="h1" gutterBottom>
//           {locationName ? `${locationName}` : t('weather.setLocation')}
//         </Typography>

//         <Button
//           variant="contained"
//           startIcon={<MyLocationIcon />} // 아이콘 추가
//           color="secondary" // 눈에 띄는 색상
//           onClick={() => navigate('/settings/mytown')}
//           sx={{
//             marginTop: 2,
//             padding: '12px 24px', // 버튼 크기 확대
//             fontSize: '1.2rem', // 텍스트 크기 확대
//             borderRadius: '8px', // 둥근 모서리
//             boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // 그림자 추가
//             background: 'linear-gradient(45deg, #6EC1E4, #1E90FF)', // 그라데이션
//             '&:hover': {
//               background: 'linear-gradient(45deg, #1E90FF, #6EC1E4)', // 호버 효과
//             },
//           }}
//         >
//           {t('myTown.title')}
//         </Button>

//         <Typography variant="body2" sx={{ marginTop: 1, color: '#888' }}>
//           {t('setLocationHint')}
//         </Typography>
//       </Container>

//       {currentUser && (
//         <>
//           <Header />
//           <CurrentWeather />
//           <TimeForecast />
//           <DayForecast />
//         </>
//       )}
//     </>
//   );
// }

// export default MyWorldWeather;

function MyWorldWeather() {
  const [locationName, setLocationName] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Use the translation hook
  const [backgroundImage, setBackgroundImage] = useState(); // Default to sunny
  const currentUser = useSelector((state) => state.auth.currentUser);

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => ({
    loading: state.app.isLoading,
  }));

  useEffect(() => {
    const fetchLocation = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const selectedTown = userData.selectedTown;
          const locationData =
            selectedTown === 1 ? userData.town1 : userData.town2;
          if (locationData) {
            setLocationName(locationData.name);
            dispatch(
              fetchWeather({
                lat: locationData.lat,
                lng: locationData.lng,
              })
            );
          }
        }
      }
    };

    if (currentUser) {
      fetchLocation(); // Fetch only when user is logged in
    }
  }, [currentUser, t]);

  // 전체 JSX에 currentUser 조건 적용
  return currentUser ? (
    <>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center', // 텍스트와 버튼을 가운데 정렬
          padding: '20px', // 전체 여백 추가
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          {locationName ? `${locationName}` : t('weather.setLocation')}
        </Typography>

        <Button
          variant="contained"
          startIcon={<MyLocationIcon />} // 아이콘 추가
          color="secondary" // 눈에 띄는 색상
          onClick={() => navigate('/settings/mytown')}
          sx={{
            marginTop: 2,
            padding: '12px 24px', // 버튼 크기 확대
            fontSize: '1.2rem', // 텍스트 크기 확대
            borderRadius: '8px', // 둥근 모서리
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // 그림자 추가
            background: 'linear-gradient(45deg, #6EC1E4, #1E90FF)', // 그라데이션
            '&:hover': {
              background: 'linear-gradient(45deg, #1E90FF, #6EC1E4)', // 호버 효과
            },
          }}
        >
          {t('myTown.title')}
        </Button>

        <Typography variant="body2" sx={{ marginTop: 1, color: '#888' }}>
          {t('setLocationHint')}
        </Typography>
      </Container>

      <Header />
      <CurrentWeather />
      <TimeForecast />
      <DayForecast />
    </>
  ) : (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: '100vh',
      }}
    >
      {/* <Typography variant="h5">{t('auth.loginRequired')}</Typography> */}
    </Container>
  );
}

export default MyWorldWeather;
