import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Button,
  Box,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  Breadcrumbs,
  Link,
  Paper,
  Fab,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Add as AddIcon } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Product from './Product';
import ProductCategorySelector from './Stores/ProductCategorySelector';
import ProductBrandSelector from './Stores/ProductBrandSelector';
import moment from 'moment';
import { incrementVisitorCount } from './Utils/VisitorCounter';
import {
  db,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  doc,
  limit,
  startAfter,
} from './firebaseConfig';
import {
  setSearchCategory,
  setSearchCategoryPath,
  setSearchKeyword,
  resetSearchKeyword,
  resetSearchCategory,
  resetSearchCategoryPath,
  setSearchBrand,
  resetSearchBrand,
  setSearchBrandPath,
  resetSearchBrandPath,
} from './Redux/reducers/searchReducer';
import { useTranslation } from 'react-i18next';
import { googleTranslate } from './Utils/GoogleTranslation';
import { fetchUserAgreementStatus } from './Utils/Agreement';
import { GeoPoint } from 'firebase/firestore';
import { getUserLocation } from './Utils/Location';
import {
  exportFetchUserProductsByCoordinatesAsArray,
  exportFetchUserProductsByLastDocAsMap,
  exportFetchStoreProductsByCoordinatesAsMap,
  exportFetchStoresByCoordinatesAsMap,
  exportFetchStoresByLastDocAsMap,
  exportFetchStoresPerPageAsMap,
  exportFilterAndSortProducts,
  exportFetchSelectedTownByUserId,
  exportFetchUserProductsByNotLoggedIn,
  exportFetchStoresByNotLoggedIn,
} from './Utils/LocationStoresAndProducts';

import useMessageBox from './Common/useMessageBox';
import useUserActivity from './useUserActivity';
import ConcertStadium from './Concert/ConcertStadium';
import ConcertStadium3D from './Concert/ConcertStadium3D';
import Stadium from './Concert/Stadium';
import styles from './css/home.module.css';
import StoreProduct from './Stores/StoreProduct';
import Lottie from 'lottie-react';
import noUserProductResults from './assets/lottie/no-userproduct-results.json';
import noStoreProductResults from './assets/lottie/no-storeproduct-results.json';
import noStoreResults from './assets/lottie/no-store-results.json';
import TermsCheck from './Stores/Admin/TermsCheck';
import PrivacyCheck from './Stores/Admin/PrivacyCheck';
import MobileNavBar from './NavBar/MobileNavBar';
import MobileMenuAppBar from './NavBar/MobileMenuAppBar';
import NotificationBanner from './NotificationBanner';
import Store from './Stores/Store';
import UserRatings from './UserRating';
import RatingForm from './UserRatingForm';
import UserRatingForm from './UserRatingForm';
import MyTownWeather from './Weather/MyTownWeather';
import MyWorldWeather from './Weather/MyWorldWeather';

function Home() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userProducts, setUserProducts] = useState([]);
  const [lastUserProduct, setLastUserProduct] = useState(null);
  const [hasMoreUserProducts, setHasMoreUserProducts] = useState(true);
  const [userProductsPage, setUserProductsPage] = useState(0);
  const [userProductsTotalCount, setUserProductsTotalCount] = useState(0);
  const [storeProducts, setStoreProducts] = useState([]);
  const [lastStoreProduct, setLastStoreProduct] = useState(null);
  const [hasMoreStoreProducts, setHasMoreStoreProducts] = useState(true);
  const [storeProductsPage, setStoreProductsPage] = useState(0);
  const [storeProductsTotalCount, setStoreProductsTotalCount] = useState(0);
  const [stores, setStores] = useState([]);
  const [lastStore, setLastStore] = useState(null);
  const [hasMoreStores, setHasMoreStores] = useState(true);
  const [storesPage, setStoresPage] = useState(0);
  const [storesTotalCount, setStoresTotalCount] = useState(0);
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] =
    useMessageBox();

  const itemsPerPage = 10;
  const searchKeyword = useSelector((state) => state.search.searchKeyword);
  const searchCategory = useSelector((state) => state.search.searchCategory);
  const searchCategoryPath = useSelector(
    (state) => state.search.searchCategoryPath
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [needsTermsCheck, setNeedsTermsCheck] = useState(false);
  const [needsPrivacyCheck, setNeedsPrivacyCheck] = useState(false);

  useEffect(() => {
    const updateVisitorCounts = async () => {
      await incrementVisitorCount();
    };

    updateVisitorCounts();
  }, []);

  useEffect(() => {
    const checkAgreements = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists() && userDoc.data().step === 'signupCompleted') {
          const { needsTerms, needsPrivacy } = await fetchUserAgreementStatus(
            currentUser.uid
          );
          setNeedsTermsCheck(needsTerms);
          setNeedsPrivacyCheck(needsPrivacy);
        }
      }
      setLoading(false); // 로딩 완료
    };

    checkAgreements();
  }, [currentUser]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const {
    logViewedProduct,
    logPurchasedProduct,
    logSearchedKeyword,
    logSearchedCategory,
    logSearchedBrand,
  } = useUserActivity(currentUser?.uid);

  useEffect(() => {
    const fetchUserProducts = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (!currentUser) {
          if (userProductsPage === 0) {
            setUserProducts([]);
          }
          const userProductsResponse =
            await exportFetchUserProductsByNotLoggedIn(
              itemsPerPage,
              userProductsPage
            );

          if (
            userProductsResponse &&
            Array.isArray(userProductsResponse.products)
          ) {
            setUserProducts((prevProducts) => [
              ...prevProducts,
              ...userProductsResponse.products,
            ]);

            setHasMoreUserProducts(
              userProductsResponse.products.length === itemsPerPage &&
                userProductsResponse.products.length !== 0
            );
            setUserProductsTotalCount(userProductsResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching products: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchUserProducts();
  }, [userProductsPage, currentUser]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (!currentUser) {
          if (storesPage === 0) {
            setStoreProducts([]);
          }
          const storesResponse = await exportFetchStoresByNotLoggedIn(
            itemsPerPage,
            storesPage
          );

          if (storesResponse && Array.isArray(storesResponse.stores)) {
            setStores((prevStores) => [
              ...prevStores,
              ...storesResponse.stores,
            ]);
            setHasMoreStores(storesResponse.stores.length === itemsPerPage);
            setStoresTotalCount(storesResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching stores: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchStores();
  }, [storesPage, currentUser]);

  useEffect(() => {
    const fetchStoreProducts = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (currentUser) {
          const location = await exportFetchSelectedTownByUserId(
            currentUser.uid
          );
          if (!location) {
            throw new Error('Failed to fetch location.');
          }
          const storeProductsResponse =
            await exportFetchStoreProductsByCoordinatesAsMap(
              location.lat,
              location.lng,
              location.radius,
              currentUser.uid
            );

          if (storeProductsResponse && storeProductsResponse.products) {
            setStoreProducts(Object.values(storeProductsResponse.products));
            setHasMoreStoreProducts(storeProductsResponse.products.length > 0);
            setUserProductsTotalCount(storeProductsResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching store products:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching store products: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchStoreProducts();
  }, [storeProductsPage, currentUser]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        if (currentUser) {
          const location = await exportFetchSelectedTownByUserId(
            currentUser.uid
          );
          if (!location) {
            throw new Error('Failed to fetch location.');
          }
          const storesResponse = await exportFetchStoresByCoordinatesAsMap(
            location.lat,
            location.lng,
            location.radius,
            currentUser.uid,
            storesPage,
            itemsPerPage
          );

          if (storesResponse && Array.isArray(storesResponse.stores)) {
            setStores((prevStores) => [
              ...prevStores,
              ...storesResponse.stores,
            ]);
            setHasMoreStores(storesResponse.stores.length === itemsPerPage);
            setStoresTotalCount(storesResponse.totalCount);
          } else {
            throw new Error('Unexpected response format');
          }
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
        setError((prevErrors) => [
          ...prevErrors,
          `Error fetching stores: ${error.message}`,
        ]);
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    fetchStores();
  }, [storesPage, currentUser]);

  useEffect(() => {
    loadUserProducts();
  }, [currentUser]);

  useEffect(() => {
    loadStoreProducts();
  }, [currentUser]);

  useEffect(() => {
    loadStores();
  }, [currentUser]);

  const loadUserProducts = async () => {
    try {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location && userDoc.data().step === 'signupCompleted') {
          console.log(t('neighborhoodNotification')); // Debugging
          console.log(t('neighborhoodNotDesignatedMessage')); // Debugging
          const isConfirmed = await MessageBoxConfirm(
            t('neighborhoodNotification'),
            t('neighborhoodNotDesignatedMessage')
          );
          if (!isConfirmed) {
            throw new Error('Failed to fetch location.');
          }
          navigate('/settings/mytown');
        }
        const fetchedUserProducts =
          await exportFetchUserProductsByCoordinatesAsArray(
            location.lat,
            location.lng,
            location.radius,
            currentUser.uid
          );

        setUserProducts(fetchedUserProducts);
        setHasMoreUserProducts(
          fetchedUserProducts.length === itemsPerPage &&
            fetchedUserProducts.length !== 0
        );
        console.log('Fetched products:', userProducts);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const loadMoreUserProducts = async () => {
    if (!hasMoreUserProducts) return;

    try {
      const userProductsQuery = query(
        collection(db, 'products'),
        orderBy('createDate', 'desc'),
        startAfter(lastUserProduct || 0),
        limit(10)
      );
      const querySnapshot = await getDocs(userProductsQuery);
      const newUserProducts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUserProducts((prevProducts) => [...prevProducts, ...newUserProducts]);
      setLastUserProduct(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMoreUserProducts(
        newUserProducts.length === 10 && !querySnapshot.empty
      );
    } catch (error) {
      if (
        error.code === 'failed-precondition' ||
        error.code === 'unavailable'
      ) {
        console.error(
          'Firestore index error: Please create the required index in the Firestore console.',
          error
        );
      } else {
        console.error('Error loading more user products:', error);
      }
    }
  };

  const loadStoreProducts = async () => {
    try {
      if (currentUser) {
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location) {
          throw new Error('Failed to fetch location.');
        }
        const storeProductsResponse =
          await exportFetchStoreProductsByCoordinatesAsMap(
            location.lat,
            location.lng,
            location.radius,
            currentUser.uid
          );

        if (storeProductsResponse && storeProductsResponse.products) {
          setStoreProducts(Object.values(storeProductsResponse.products));
          setHasMoreStoreProducts(storeProductsResponse.products.length > 0);
        } else {
          throw new Error('Unexpected response format');
        }
      }
    } catch (error) {
      console.error('Error fetching store products:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error fetching store products: ${error.message}`,
      ]);
    }
  };

  const loadMoreStoreProducts = async () => {
    try {
      if (!hasMoreStoreProducts) return;

      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        throw new Error('Failed to fetch location.');
      }

      const storeProductsResponse =
        await exportFetchStoreProductsByCoordinatesAsMap(
          location.lat,
          location.lng,
          location.radius,
          currentUser.uid,
          lastStoreProduct
        );

      if (storeProductsResponse && storeProductsResponse.products) {
        setStoreProducts((prevProducts) => [
          ...prevProducts,
          ...Object.values(storeProductsResponse.products),
        ]);
        setHasMoreStoreProducts(storeProductsResponse.products.length > 0);
        setLastStoreProduct(
          storeProductsResponse.lastDoc
            ? storeProductsResponse.lastDoc.id
            : null
        );
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error loading more store products:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error loading more store products: ${error.message}`,
      ]);
    }
  };

  const loadStores = async () => {
    try {
      if (currentUser) {
        const location = await exportFetchSelectedTownByUserId(currentUser.uid);
        if (!location) {
          throw new Error('Failed to fetch location.');
        }
        const storesResponse = await exportFetchStoresByCoordinatesAsMap(
          location.lat,
          location.lng,
          location.radius,
          currentUser.uid
        );

        if (storesResponse && storesResponse.stores) {
          setStores(storesResponse.stores);
          setHasMoreStores(storesResponse.stores.length > 0);
          setLastStore(storesResponse.lastDoc);
        } else {
          throw new Error('Unexpected response format');
        }
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error fetching stores: ${error.message}`,
      ]);
    }
  };

  const loadMoreStores = async () => {
    try {
      if (!hasMoreStores) return;

      const location = await exportFetchSelectedTownByUserId(currentUser.uid);
      if (!location) {
        throw new Error('Failed to fetch location.');
      }

      const storesResponse = await exportFetchStoresByCoordinatesAsMap(
        location.lat,
        location.lng,
        location.radius,
        currentUser.uid,
        lastStore
      );

      if (storesResponse && storesResponse.stores) {
        setStores((prevStores) => [...prevStores, ...storesResponse.stores]);
        setHasMoreStores(storesResponse.stores.length > 0);
        setLastStore(storesResponse.lastDoc ? storesResponse.lastDoc.id : null);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error loading more stores:', error);
      setError((prevErrors) => [
        ...prevErrors,
        `Error loading more stores: ${error.message}`,
      ]);
    }
  };

  const handleUserProductClick = (product) => {
    navigate(`/detail/${product.id}`);
    logViewedProduct(product.id);
  };

  const handleStoreProductClick = (product) => {
    navigate(`/product_detail/${product.uid}/${product.id}`);
    logViewedProduct(product.id);
  };

  const handleStoreClick = (store) => {
    navigate(`/store_home/${store.id}`);
  };

  const handleSortChange = (sortKey) => {
    const sortedUserProducts = [...userProducts];
    const sortedStores = [...stores];
    switch (sortKey) {
      case 'latest':
        sortedUserProducts.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
        sortedStores.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );
        break;
      case 'popularity':
        sortedUserProducts.sort((a, b) => b.likes - a.likes);
        sortedStores.sort((a, b) => b.likes - a.likes);
        break;
      case 'lowPrice':
        sortedUserProducts.sort((a, b) => a.price - b.price);
        break;
      case 'highPrice':
        sortedUserProducts.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
    setUserProducts(sortedUserProducts);
  };

  if (loading) return <CircularProgress />;

  return (
    <Box
      p={2}
      sx={{
        width: '100%',
        maxWidth: '100%',
      }}
    >
      {/* <MyTownWeather /> */}
      <MyWorldWeather />

      <Box
        sx={{
          display: 'inline-block',
          marginTop: '20px',
          borderRadius: '50px',
          padding: '8px 16px',
          marginBottom: '16px',
          backgroundColor: 'transparent',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('todaysRecommendations')}
        </Typography>
      </Box>
      {needsTermsCheck && (
        <>
          <TermsCheck onAgreementComplete={() => setNeedsTermsCheck(false)} />
        </>
      )}
      {!needsTermsCheck && needsPrivacyCheck && (
        <>
          <PrivacyCheck
            onAgreementComplete={() => setNeedsPrivacyCheck(false)}
          />
        </>
      )}
      <Grid container spacing={2}>
        {userProducts.length > 0 && (
          <Grid item xs={12}>
            <Breadcrumbs separator=" | " aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => handleSortChange('latest')}
                sx={{ cursor: 'pointer' }}
              >
                {t('latest')}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleSortChange('popularity')}
                sx={{ cursor: 'pointer' }}
              >
                {t('popularity')}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleSortChange('lowPrice')}
                sx={{ cursor: 'pointer' }}
              >
                {t('lowPrice')}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleSortChange('highPrice')}
                sx={{ cursor: 'pointer' }}
              >
                {t('highPrice')}
              </Link>
            </Breadcrumbs>
          </Grid>
        )}
        {MessageBoxRender()}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {t('userProducts')}
          </Typography>
          {userProducts.length === 0 ? (
            <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
              <Lottie
                animationData={noUserProductResults}
                style={{ height: 300 }}
              />
              <Typography textAlign="center" sx={{ width: '100%' }}>
                {t('noProducts')}
              </Typography>
            </Paper>
          ) : isMobile ? (
            <Box
              id="parentScrollBox"
              sx={{
                marginTop: '20px',
                height: 'calc(80vh - 70px)',
                overflow: 'auto',
              }}
            >
              <InfiniteScroll
                dataLength={userProducts.length}
                next={loadMoreUserProducts}
                hasMore={hasMoreUserProducts}
                // loader={<CircularProgress />}
                loader={<Typography>{t('loading')}</Typography>}
                endMessage={
                  <Typography variant="body2">{t('noMoreProducts')}</Typography>
                }
                scrollableTarget="parentScrollBox"
              >
                <Grid container spacing={2}>
                  {userProducts.map((product) => (
                    <Grid item xs={12} key={product.id}>
                      <Product
                        product={product}
                        onClick={() => handleUserProductClick(product)}
                        horizontal={isMobile} // pass the prop for mobile view
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                {userProducts.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.id}
                  >
                    <Product
                      product={product}
                      onClick={() => handleUserProductClick(product)}
                    />
                  </Grid>
                ))}
              </Grid>
              <TablePagination
                component="div"
                count={userProductsTotalCount}
                page={userProductsPage}
                onPageChange={(e, newPage) => setUserProductsPage(newPage)}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {t('localStores')}
          </Typography>
          {stores.length === 0 ? (
            <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
              <Lottie animationData={noStoreResults} style={{ height: 300 }} />
              <Typography textAlign="center" sx={{ width: '100%' }}>
                {t('noStores')}
              </Typography>
            </Paper>
          ) : isMobile ? (
            <Box
              id="storesScrollBox"
              sx={{
                marginTop: '20px',
                height: 'calc(80vh - 70px)',
                overflow: 'auto',
              }}
            >
              <InfiniteScroll
                dataLength={stores.length}
                next={loadMoreStores}
                hasMore={hasMoreStores}
                loader={<Typography>{t('loading')}</Typography>}
                endMessage={
                  <Typography variant="body2">{t('noMoreStores')}</Typography>
                }
                scrollableTarget="storesScrollBox"
              >
                <Grid container spacing={0}>
                  {stores.map((store) => (
                    <Grid item xs={12} key={store.id}>
                      <Store store={store} horizontal={isMobile} />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                {stores.map((store) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={store.id}>
                    <Store store={store} horizontal={isMobile} />
                  </Grid>
                ))}
              </Grid>
              <TablePagination
                component="div"
                count={stores.length}
                page={storesPage}
                onPageChange={(e, newPage) => setStoresPage(newPage)}
                rowsPerPage={itemsPerPage}
                rowsPerPageOptions={[itemsPerPage]}
              />
            </>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {t('storeProducts')}
          </Typography>
          {storeProducts.length === 0 ? (
            <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Lottie animationData={noStoreProductResults} style={{ height: 300 }} />
              <Typography textAlign="center" sx={{ width: '100%' }}>
                {t('noProducts')}
              </Typography>
            </Paper>
          ) : isMobile ? (
            <Box
              id="storeScrollBox"
              sx={{
                marginTop: '20px',
                height: 'calc(80vh - 70px)',
                overflow: 'auto',
              }}
            >
              <InfiniteScroll
                dataLength={storeProducts.length}
                next={loadMoreStoreProducts}
                hasMore={hasMoreStoreProducts}
                loader={<Typography>{t('loading')}</Typography>}
                endMessage={
                  <Typography variant="body2">{t('noMoreProducts')}</Typography>
                }
                scrollableTarget="storeScrollBox"
              >
                <Grid container spacing={2}>
                  {storeProducts.map((product) => (
                    <Grid item xs={12} key={product.id}>
                      <Product
                        product={product}
                        onClick={() => handleStoreProductClick(product)}
                        horizontal={isMobile} // pass the prop for mobile view
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                {storeProducts.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.id}
                  >
                    <Product
                      product={product}
                      onClick={() => handleStoreProductClick(product)}
                    />
                  </Grid>
                ))}
              </Grid>
              <TablePagination
                component="div"
                count={storeProductsTotalCount}
                page={storeProductsPage}
                onPageChange={(e, newPage) => setStoreProductsPage(newPage)}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
              />
            </>
          )}
        </Grid> */}

        <Fab
          variant="extended"
          color="primary"
          aria-label="upload"
          component={RouterLink}
          to="/upload"
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 20,
            zIndex: 1000,
            backgroundColor: 'primary',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#1565c0',
            },
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '0 16px',
            borderRadius: '50px',
          }}
        >
          <AddIcon sx={{ mr: 1 }} />
          {t('uploadProduct')}
        </Fab>
      </Grid>
    </Box>
  );
}

export default Home;
