// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   doc,
//   getDoc,
//   setDoc,
//   updateDoc,
//   query,
//   where,
//   orderBy,
//   getDocs
// } from './firebaseConfig';

// import { useNavigate } from 'react-router-dom';
// import Keyword from './Keyword';
// import NavBar from './NavBar';

// function MyKeyword() {
//   const [userKeywords, setUserKeywords] = useState([]);
//   const navigate = useNavigate();
//   const currentUser = useSelector(state => state.auth.currentUser);

//     useEffect(() => {
//       // 초기 로딩 시 사용자의 키워드를 가져옵니다.
//       const fetchKeywords = async () => {
//           const userRef = doc(db, 'users', currentUser.uid);
//           const userDoc = await getDoc(userRef);

//           if (userDoc.exists()) {
//               const data = userDoc.data();
//               if (data.keywords) {
//                   setUserKeywords(data.keywords);
//               }
//           } else {
//               // 문서가 없을 때 초기 keywords 값을 설정하여 새 문서를 생성합니다.
//               const initialKeywords = [];  // 초기 값 설정 (또는 원하는 값으로 변경)
//               await setDoc(userRef, { keywords: initialKeywords });
//               setUserKeywords(initialKeywords);
//           }
//       };

//       fetchKeywords();
//   }, [currentUser, db]);

//   const handleUpdateKeywords = async (updatedKeywords) => {
//       setUserKeywords(updatedKeywords);

//       // Firebase에 변경된 키워드를 업데이트합니다.
//       const userId = auth.currentUser.uid;
//       const userRef = doc(db, 'users', userId);

//       await updateDoc(userRef, {
//           keywords: updatedKeywords
//       });
//   }

//   return (

//     <div className="container clear-fix">
//       <button onClick={() => navigate('/settings')} style={{
//         background: 'none',
//         border: 'none',
//         fontSize: '20px',
//         cursor: 'pointer',
//         color: '#333'  // <- 텍스트 색상. 필요에 따라 변경 가능
//       }}>&larr;</button>
//       <br />

//       <div className="d-flex justify-content-between align-items-center mb-4">
//         <h3>알림키워드설정</h3>
//       </div>
//       <Keyword initialKeywords={userKeywords} onUpdateKeywords={handleUpdateKeywords} />
//     </div>

//   );
// }

// export default MyKeyword;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  firebaseApp,
  db,
  auth,
  analytics,
  storage,
  functions,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
  getDocs,
} from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Button, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Keyword from './Keyword';

function MyKeyword() {
  const [userKeywords, setUserKeywords] = useState([]);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchKeywords = async () => {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        if (data.keywords) {
          setUserKeywords(data.keywords);
        }
      } else {
        const initialKeywords = [];
        await setDoc(userRef, { keywords: initialKeywords });
        setUserKeywords(initialKeywords);
      }
    };

    fetchKeywords();
  }, [currentUser, db]);

  const handleUpdateKeywords = async (updatedKeywords) => {
    setUserKeywords(updatedKeywords);

    const userId = auth.currentUser.uid;
    const userRef = doc(db, 'users', userId);

    await updateDoc(userRef, {
      keywords: updatedKeywords,
    });
  };

  return (
    <Container maxWidth="sm">
      <Box mt={2} mb={4}>
        <IconButton onClick={() => navigate('/settings')} color="primary">
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        알림키워드설정
      </Typography>
      <Keyword
        initialKeywords={userKeywords}
        onUpdateKeywords={handleUpdateKeywords}
      />
    </Container>
  );
}

export default MyKeyword;
