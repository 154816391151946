// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { ReactComponent as HighIcon } from '../../assets/high-icon.svg';
// import { ReactComponent as HumidityIcon } from '../../assets/humidity-icon.svg';
// import { ReactComponent as LowIcon } from '../../assets/low-icon.svg';
// import { ReactComponent as PressureIcon } from '../../assets/pressure-icon.svg';
// import { ReactComponent as WindIcon } from '../../assets/wind-icon.svg';
// import { AppStore } from '../../Redux/store'

// import { changeTempUnit } from '../../Redux/reducers/appReducer';
// import { kmToMile, TempUnit } from '../../Utils/unitConversion';
// import ToggleSwitch from '../ui/ToggleSwitch/ToggleSwitch';
// import WeatherIcon from './WeatherIcon';
// import {
//   CurrentWeatherStatus,
//   CurrentWeatherContainer,
//   CurrentWeatherInfo,
//   FeelsLike,
//   HighLowContainer,
//   InfoRow,
//   SectionTitle,
//   WeatherContainer,
//   WeatherDegree,
// } from './styled';
// import Temperature from './Temperature';

// const CurrentWeather: React.FC = () => {
//   const { weather, degreeType, isInitial, isError } = useSelector((store: AppStore) => ({
//     weather: store.weather.weatherData,
//     degreeType: store.app.tempUnit,
//     isInitial: store.app.isInitial,
//     isError: store.weather.isError,
//   }));
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (isError) {
//       console.log('Cannot load weather for this place');
//     }
//   }, [isError]);

//   if (isInitial) return <></>;
  
//   return (
//     <WeatherContainer>
//       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <SectionTitle>오늘의 날씨</SectionTitle>
//         <div>
//           <ToggleSwitch onClick={() => dispatch(changeTempUnit())} />
//         </div>
//       </div>
//       <CurrentWeatherContainer>
//         <CurrentWeatherStatus>
//           <h4>{weather.name}</h4>
//           <div style={{ display: 'flex' }}>
//             <WeatherIcon code={weather.weather.id} big />
//             <span>
//               <Temperature value={weather.main.temp} />
//               <sup>&deg;</sup>
//             </span>
//           </div>
//           <h6>{weather.weather.description}</h6>
//         </CurrentWeatherStatus>

//         <CurrentWeatherInfo>
//           <FeelsLike>
//             Feels like <Temperature value={weather.main.feels_like} />
//             <sup>&deg;</sup>
//           </FeelsLike>
//           <HighLowContainer>
//             <WeatherDegree>
//               <HighIcon />
//               <Temperature value={weather.main.temp_max} />
//               <sup>&deg;</sup>
//             </WeatherDegree>
//             <WeatherDegree>
//               <LowIcon />
//               <Temperature value={weather.main.temp_min} />
//               <sup>&deg;</sup>
//             </WeatherDegree>
//           </HighLowContainer>
//           <InfoRow>
//             <div>
//               <HumidityIcon /> Humidity
//             </div>
//             <span>{weather.main.humidity}%</span>
//           </InfoRow>
//           <InfoRow>
//             <div>
//               <WindIcon /> Wind
//             </div>
//             <span>
//               {degreeType === TempUnit.CELCIUS ? weather.wind.speed : kmToMile(weather.wind.speed)}
//               {degreeType === TempUnit.CELCIUS ? 'kph' : 'mph'}
//             </span>
//           </InfoRow>
//           <InfoRow>
//             <div>
//               <PressureIcon /> Pressure
//             </div>
//             <span>{weather.main.pressure}hPa</span>
//           </InfoRow>
//         </CurrentWeatherInfo>
//       </CurrentWeatherContainer>
//     </WeatherContainer>
//   );
// };

// export default CurrentWeather;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HighIcon } from '../../assets/high-icon.svg';
import { ReactComponent as HumidityIcon } from '../../assets/humidity-icon.svg';
import { ReactComponent as LowIcon } from '../../assets/low-icon.svg';
import { ReactComponent as PressureIcon } from '../../assets/pressure-icon.svg';
import { ReactComponent as WindIcon } from '../../assets/wind-icon.svg';
import { AppStore } from '../../Redux/store';
import { changeTempUnit } from '../../Redux/reducers/appReducer';
import { kmToMile, TempUnit } from '../../Utils/unitConversion';
import ToggleSwitch from '../ui/ToggleSwitch/ToggleSwitch';
import WeatherIcon from './WeatherIcon';
import {
  CurrentWeatherStatus,
  CurrentWeatherContainer,
  CurrentWeatherInfo,
  FeelsLike,
  HighLowContainer,
  InfoRow,
  SectionTitle,
  WeatherContainer,
  WeatherDegree,
} from './styled';
import Temperature from './Temperature';

const CurrentWeather: React.FC = () => {
  const { t } = useTranslation();
  const { weather, degreeType, isInitial, isError } = useSelector((store: AppStore) => ({
    weather: store.weather.weatherData,
    degreeType: store.app.tempUnit,
    isInitial: store.app.isInitial,
    isError: store.weather.isError,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      console.log(t('error_loading_weather'));
    }
  }, [isError, t]);

  if (isInitial) return <></>;

  return (
    <WeatherContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionTitle>{t('weather.today')}</SectionTitle>
        <div>
          <ToggleSwitch onClick={() => dispatch(changeTempUnit())} />
        </div>
      </div>
      <CurrentWeatherContainer>
        <CurrentWeatherStatus>
          <h4>{weather.name}</h4>
          <div style={{ display: 'flex' }}>
            <WeatherIcon code={weather.weather.id} big />
            <span>
              <Temperature value={weather.main.temp} />
              <sup>&deg;</sup>
            </span>
          </div>
          <h6>{weather.weather.description}</h6>
        </CurrentWeatherStatus>

        <CurrentWeatherInfo>
          <FeelsLike>
            {t('weather.feels_like')} <Temperature value={weather.main.feels_like} />
            <sup>&deg;</sup>
          </FeelsLike>
          <HighLowContainer>
            <WeatherDegree>
              <HighIcon />
              <Temperature value={weather.main.temp_max} />
              <sup>&deg;</sup>
            </WeatherDegree>
            <WeatherDegree>
              <LowIcon />
              <Temperature value={weather.main.temp_min} />
              <sup>&deg;</sup>
            </WeatherDegree>
          </HighLowContainer>
          <InfoRow>
            <div>
              <HumidityIcon /> {t('weather.humidity')}
            </div>
            <span>{weather.main.humidity}%</span>
          </InfoRow>
          <InfoRow>
            <div>
              <WindIcon /> {t('weather.wind')}
            </div>
            <span>
              {degreeType === TempUnit.CELCIUS ? weather.wind.speed : kmToMile(weather.wind.speed)}
              {degreeType === TempUnit.CELCIUS ? t('weather.temp_unit_celsius') : t('weather.temp_unit_fahrenheit')}
            </span>
          </InfoRow>
          <InfoRow>
            <div>
              <PressureIcon /> {t('weather.pressure')}
            </div>
            <span>{weather.main.pressure}hPa</span>
          </InfoRow>
        </CurrentWeatherInfo>
      </CurrentWeatherContainer>
    </WeatherContainer>
  );
};

export default CurrentWeather;
