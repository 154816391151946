// import { Box, Typography } from '@mui/material';

// const Ribbon = ({ text }) => {
//   return (
//     <Box
//       sx={{
//         position: 'absolute',
//         top: '10px',
//         left: '-30px',
//         width: '150px',
//         backgroundColor: 'red',
//         color: 'white',
//         textAlign: 'center',
//         margin: '15px 15px',
//         transform: 'rotate(-45deg)', // 대각선 회전
//         zIndex: 1,
//         boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//       }}
//     >
//       <Typography variant="caption">{text}</Typography>
//     </Box>
//   );
// };

// export default Ribbon;

import { Box, Typography } from '@mui/material';
import redRibbon from '../assets/images/red-ribbon.webp'; // 이미지 경로를 수정해 주세요

const Ribbon = ({ text }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '10px',
        left: '-30px',
        width: '150px',
        height: '40px', // 리본 높이를 설정
        // backgroundImage: `url(${redRibbon})`, // 배경 이미지 설정
        backgroundColor: 'red',
        backgroundSize: 'cover', // 이미지가 리본을 덮도록 설정
        color: 'white',
        textAlign: 'center',
        transform: 'rotate(-45deg)', // 대각선 회전
        zIndex: 1,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center', // 텍스트를 세로로 가운데 정렬
        justifyContent: 'center', // 텍스트를 가로로 가운데 정렬
        // backgroundColor: 'transparent', // 불필요한 배경색 제거
      }}
    >
      <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default Ribbon;
